import React, { useRef, useEffect, useState } from "react";

export function Photo(props) {
  const imgRef = useRef(null);
  const [size, setSize] = useState({});
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const img = new Image(); 
    const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    img.onload = function(){
      imgRef.current.src = this.src;
      const width = Math.min(img.width, windowWidth / 2);
      const sizeRatio = width / img.width;
      const height = img.height * sizeRatio;
      setSize({
        height,
        width,
      });
      props.ready && props.ready();
      setReady(true);
    };
    img.src = props.img;
  }, []);

  return (
    <span
      style={{
        boxShadow:
          "0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
        overflow: "hidden",
        border: "15px solid white",
        width: size.width ? size.width : 'auto',
        height: size.height ? size.height : 'auto',
        position: "absolute",
        top: `${props.y_translate}px`,
        left: `${props.x_translate}px`,
        transform: `rotate(${props.rotation}deg)`,
        opacity: ready ? 1 : 0,
        transition: 'opacity 1s ease',
      }}
    >
      <img
        ref={imgRef}
        alt=""
        style={{
          maxWidth: "50vw",
          width: "100%"
        }}
      />
    </span>
  );
}

export function PhotoElements(props) {
  const state = useRef({ count: 0 });

  const onReady = () => {
    state.current.count += 1;
    if (state.current.count >= props.images.length) {
      props.ready && props.ready();
    }
  }

  const imageEls = props.images.map(img => (
    <Photo
      key={img.url}
      img={img.url}
      rotation={img.rotation}
      x_translate={img.x_translate}
      y_translate={img.y_translate}
      ready={onReady}
    />
  ));

  return (
    <span
      style={{
        opacity: props.display ? 1 : 0,
        transition: 'opacity 1s ease',
      }}
    >
      {imageEls}
    </span>
  );
}
