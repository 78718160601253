import React from 'react';

export function BackgroundImage(props) {
  return (
    <div
      style={{
        backgroundImage: `url(${props.src})`,
        width: "100%",
        minHeight: '100vh',
        height: `${props.height}px`,
        backgroundSize: "100%",
        backgroundRepeat: "repeat-y",
        overflow: 'hidden',
      }}
    >
      {props.children}
    </div>
  );
}
