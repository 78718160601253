import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import woodImg from "./woodLarge.jpg";
import { BackgroundImage } from "./Components/BackgroundImage";
import { PhotoElements } from "./Components/Photo";
import { shuffle, generateImageObject } from "./utils";

let imagesArray = [
  "https://scontent-lht6-1.xx.fbcdn.net/v/t31.0-8/27747454_1934438389917905_2193220430344533494_o.jpg?_nc_cat=109&_nc_ht=scontent-lht6-1.xx&oh=e1cd9ba08e037b40f06d287d5cad286f&oe=5CE9A189",
  "https://scontent-lht6-1.xx.fbcdn.net/v/t1.0-9/37488733_2138082089553533_3588810892273254400_n.jpg?_nc_cat=103&_nc_ht=scontent-lht6-1.xx&oh=088368dbf6c890640e9d48dea109ea56&oe=5CFDA7D8",
  "https://scontent-lht6-1.xx.fbcdn.net/v/t1.0-9/37874917_1832645783481886_3307051468468518912_o.jpg?_nc_cat=105&_nc_ht=scontent-lht6-1.xx&oh=f4ebbe9dd7bced91a7cd77240496f326&oe=5CE0B77B",
  "https://scontent-lht6-1.xx.fbcdn.net/v/t1.0-9/37905953_1832649473481517_3502429608072970240_o.jpg?_nc_cat=103&_nc_ht=scontent-lht6-1.xx&oh=a7dc9210579d673040bec46900640a9f&oe=5CFE2F71",

  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/37909084_1832647016815096_1413540523855052800_o.png?alt=media&token=ba680885-b3f4-4d5a-811c-f5add66514f7",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/40504700_10215519083264791_4810407300445503488_n.png?alt=media&token=35f5373d-f35d-4dd4-b41b-7bf3006f82d5",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/46165989_2124423774255013_1108243068655501312_o.png?alt=media&token=f7a737ac-342b-4c1f-9555-0ce6042c73f0",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/48391586_10156682611236480_8855462123212374016_o.png?alt=media&token=ec87e9fd-6999-460e-a3bb-276943c9c55e",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/50532362_10156778904286480_6090746904038080512_o.png?alt=media&token=baafe143-1752-445f-824d-99352d395297",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/50949563_10214367053184291_8076019124850917376_n.png?alt=media&token=f8f4d6c4-5444-41ef-9ddb-0816e8a3d4ce",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56528944_2337533179610737_8074605045523415040_n.png?alt=media&token=87274c6c-8afe-46a6-8386-1c352ef10242",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56547268_2337547259609329_2408955703587766272_n.png?alt=media&token=a68d370d-98c9-4caa-9a42-d197a4967020",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56587636_2337539829610072_8456433659681964032_n.png?alt=media&token=bd6e9222-cc22-4572-8642-c266f2b69700",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56706246_2337539532943435_4463897035585617920_n.png?alt=media&token=931171b5-88d9-4bef-8185-b1a8941ed576",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56775729_2337545952942793_3224919508946780160_n.png?alt=media&token=a85e72ef-76ea-458a-a7eb-c729c5f6b15a",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56830133_2337540602943328_1251148373034008576_n.png?alt=media&token=1bda743a-3191-4150-95f2-4b026cb9b9de",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56842757_2337545182942870_3385654017139933184_n.png?alt=media&token=f89ed3bc-5d2e-4fbb-a80e-f59f9c13b725",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56927219_2337540802943308_8387814680885723136_n.png?alt=media&token=3ce75f53-b07e-478e-a264-6ee330ec5ce4",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56952779_2337539636276758_1795904144909795328_n.png?alt=media&token=9d16aa2c-7e5c-4539-88e4-d9f2c3a4312d",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/56966831_2337539372943451_6570193575009583104_n.png?alt=media&token=e7ac97e0-f144-4513-9d5d-4d49108f7abd",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/57038213_2337531859610869_4792655848423817216_n.png?alt=media&token=6fb031c1-50a4-4d71-8dd4-4ad4fcfbfde6",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/57059761_2337539916276730_4366381091620126720_n.png?alt=media&token=e1c8903e-47ed-472d-b436-06a78765ac52",
  // "https://firebasestorage.googleapis.com/v0/b/bubbacominghome.appspot.com/o/57239095_2337532982944090_3142635692266356736_n.png?alt=media&token=e6b54c52-e828-48ec-84b6-13d65cb87c14",

  "https://www.dropbox.com/s/5yz2mklkozu169x/37909084_1832647016815096_1413540523855052800_o.png?raw=1",
  "https://www.dropbox.com/s/of3f2wogz1qp68m/40504700_10215519083264791_4810407300445503488_n.png?raw=1",
  "https://www.dropbox.com/s/yos9je7dzom1eod/46165989_2124423774255013_1108243068655501312_o.png?raw=1",
  "https://www.dropbox.com/s/7y260xrpk09vvlm/48391586_10156682611236480_8855462123212374016_o.png?raw=1",
  "https://www.dropbox.com/s/575ytcwdb86clhp/50532362_10156778904286480_6090746904038080512_o.png?raw=1",
  "https://www.dropbox.com/s/hmbfdfnpq9eqmtb/50949563_10214367053184291_8076019124850917376_n.png?raw=1",
  "https://www.dropbox.com/s/n3nmf55v2s2yjwc/56528944_2337533179610737_8074605045523415040_n.png?raw=1",
  "https://www.dropbox.com/s/jav2fbthq4f3njs/56547268_2337547259609329_2408955703587766272_n.png?raw=1",
  "https://www.dropbox.com/s/ita4n9607mmfb9p/56587636_2337539829610072_8456433659681964032_n.png?raw=1",
  "https://www.dropbox.com/s/as1ki9akdfq0391/56706246_2337539532943435_4463897035585617920_n.png?raw=1",
  "https://www.dropbox.com/s/ne9jden9amtt7hc/56775729_2337545952942793_3224919508946780160_n.png?raw=1",
  "https://www.dropbox.com/s/mfvbry7fktdeuq5/56830133_2337540602943328_1251148373034008576_n.png?raw=1",
  "https://www.dropbox.com/s/ujx7c17wyhkmjpk/56842757_2337545182942870_3385654017139933184_n.png?raw=1",
  "https://www.dropbox.com/s/7crbbcy0a0t332i/56927219_2337540802943308_8387814680885723136_n.png?raw=1",
  "https://www.dropbox.com/s/vqoih26f3c4sfrw/56952779_2337539636276758_1795904144909795328_n.png?raw=1",
  "https://www.dropbox.com/s/ag4nr41zx8ju6kp/56966831_2337539372943451_6570193575009583104_n.png?raw=1",
  "https://www.dropbox.com/s/k7a6phns9pj05d6/57038213_2337531859610869_4792655848423817216_n.png?raw=1",
  "https://www.dropbox.com/s/hpr9cnbecahbs6i/57059761_2337539916276730_4366381091620126720_n.png?raw=1",
  "https://www.dropbox.com/s/jq9gwutu50irs0m/57239095_2337532982944090_3142635692266356736_n.png?raw=1",
];

const canvasHeight = 5000;

let images = shuffle(imagesArray);

let imagesObj = images.map(url => generateImageObject(url, canvasHeight));

export default () => {
  const [loaded, setLoaded] = useState(false);
  const [ready, setReady] = useState(false);
  const [photosReady, setPhotosReady] = useState(false);
  const audioRef = useRef(null);
  const timerRef = useRef({
    id: null,
    startTime: null,
  });

  // useEffect(() => {
    // audioRef.current.addEventListener('canplay', () => {
    //   window.scrollTo(0, 0);
    //   const promise = audioRef.current.play();

    //   if (promise !== undefined) {
    //     promise
    //       .then(() => {
    //         console.log('playing');
    //       })
    //       .catch(err => {
    //         audioRef.current.play();
    //         console.log(err);
    //       });
    //   }
    //   setLoaded(true);
    // });
    // setTimeout(() => {
    //   setReady(true);
    // }, 3000);
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const startScroll = () => {
    window.scrollTo(0, 0);
    const totalScrollTime = 43000;
    timerRef.current.startTime = new Date().getTime();

    timerRef.current.id = setInterval(() => {
      const time = new Date().getTime();  
      const diff = time - timerRef.current.startTime;
      const perc = diff / totalScrollTime;
      if (perc >= 1) {
        clearInterval(timerRef.current.id);
      } else {
        window.scrollTo(0, canvasHeight * perc);
      }
    }, 10);
  }

  const handlePhotosReady = () => {
    setPhotosReady(true);
  }

  return (
    <BackgroundImage src={woodImg} height={canvasHeight}>
      <h1
        style={{
          // opacity: ready && photosReady ? 0 : 1,
          opacity: 1,
          transition: 'opacity 0.5s ease',
          margin: '0 auto',
          textAlign: 'center',
          fontFamily: 'Helvetica',
          paddingTop: '30vh',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          fontSize: '80px',
          zIndex: 100,
          color: '#8d0000',
        }}
      >
        <span
          style={{
            background: 'rgba(255, 255, 255, 0.4)',
            padding: '10px 50px',
            borderRadius: '5px',
            cursor: loaded ? 'default' : 'pointer',
          }}
          onClick={() => {
            if (!loaded) {
              audioRef.current.play();
              setLoaded(true);
              startScroll();
            }
          }}
        >
          Welcome Home Bubba ;*
        </span>
      </h1>
      <PhotoElements images={imagesObj} ready={handlePhotosReady} display={loaded}/>
      <audio
        ref={audioRef}
        src="https://www.dropbox.com/s/kjdvq7w3wjwbzs0/bubbacominghome.mp3?raw=1"
      >
        Your browser does not support the <code>audio</code> element.
      </audio>
    </BackgroundImage>
  );
};
